import { start, registerApplication } from 'single-spa'
import 'babel-polyfill'
import { Modules } from './enums/Modules'

declare global {
  interface Window {
    System: any
    singleSpaNavigate: any
  }
}

const matchRoute = (route: string) => {
  return (location: Location) => location.pathname.includes(`${route}`)
}

registerApplication(
  Modules.COMMON_NAME,
  () => window.System.import(`/payroll-simulation/${Modules.COMMON_NAME}.js`),
  () => true,
)

registerApplication(
  Modules.SALARY_ADVANCE_NAME,
  () =>
    window.System.import(
      `/payroll-simulation/${Modules.SALARY_ADVANCE_NAME}.js`,
    ),
  matchRoute('salary-advance'),
  {
    api: 'DEXTER',
    language: 'ptBr',
    authToken: 'SEM_TOKEN',
    clientId: 5999,
    onContinue: function(id) {
      console.log('Salary Advance ID: ', id)
    },
  },
)

start()
